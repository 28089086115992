import { Injectable } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { DateRange, DefaultMatCalendarRangeStrategy } from '@angular/material/datepicker';
import {
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
} from 'date-fns/esm';
export type CalRangeType = 'day' | 'week' | 'month' | 'quarter' | 'year' | null | undefined;
@Injectable()
export class TimeRangeStrategyService<D extends Date = Date> extends DefaultMatCalendarRangeStrategy<D> {
  private _type: CalRangeType = null;
  constructor(private dateAdapter: DateAdapter<D>) {
    super(dateAdapter);
  }
  get type() {
    return this._type;
  }
  setRangeType(type: CalRangeType) {
    this._type = type;
  }
  get isRangeActive() {
    return !this.type;
  }
  selectionFinished(date: D | DateRange<D> | null, currentRange: DateRange<D>): DateRange<D> {
    if (this.type) return this._createDateRangeFromType(date);
    if (date instanceof DateRange) return date;
    if (currentRange?.start && !currentRange.end && date > currentRange.start)
      return new DateRange(currentRange.start, date);
    return new DateRange(date, null);
  }

  createPreview(activeDate: D | null, currentRange: DateRange<D>): DateRange<D> {
    if (this.type) return this._createDateRangeFromType(activeDate);
    let start: D | null = null;
    let end: D | null = null;

    if (currentRange.start && !currentRange.end && activeDate) {
      start = currentRange.start;
      end = activeDate;
    }

    return new DateRange<D>(start, end);
  }

  private _createDateRangeFromType(_date: D | DateRange<D> | null): DateRange<D> {
    const type = this.type;
    if (_date && type) {
      const date = new Date((_date instanceof DateRange && _date.start) || (_date as any));
      switch (type) {
        case 'day': {
          return new DateRange<D>(date as any, date as any);
        }
        case 'week': {
          const start = startOfWeek(new Date(date), { weekStartsOn: 1 });
          const end = endOfWeek(new Date(date), { weekStartsOn: 1 });
          return new DateRange<D>(start as any, end as any);
        }
        case 'month': {
          const start = startOfMonth(new Date(date));
          const end = endOfMonth(new Date(date));
          return new DateRange<D>(start as any, end as any);
        }
        case 'quarter': {
          const start = startOfQuarter(new Date(date));
          const end = endOfQuarter(new Date(date));
          return new DateRange<D>(start as any, end as any);
        }
        case 'year': {
          const start = startOfYear(new Date(date));
          const end = endOfYear(new Date(date));
          return new DateRange<D>(start as any, end as any);
        }
      }
    }
    if (_date instanceof DateRange) return _date;
    return new DateRange<D>(null, null);
  }
}

import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { MAT_DATE_RANGE_SELECTION_STRATEGY } from '@angular/material/datepicker';
import { TranslateService } from '@ngx-translate/core';
import { startOfDay } from 'date-fns';
import {
  addDays,
  addMonths,
  addQuarters,
  addWeeks,
  addYears,
  endOfDay,
  endOfWeek,
  isSameDay,
  isSameYear,
  startOfWeek,
  subDays,
  subMonths,
  subQuarters,
  subWeeks,
  subYears,
} from 'date-fns/esm';
import { BehaviorSubject, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { Logger } from 'timeghost-api';
import { CalendarRangePickerService } from './calendar-range-picker/calendar-range-picker.service';
import { TimeRangeStrategyService } from './customRangeAdapter';
import {
  TIME_RANGE_CUSTOM_NAME,
  TIME_RANGE_DEFAULT_RANGES,
  TRange,
  TRangePresets,
  getTimeRangeByType,
} from './time-range-constants';

const log = new Logger('TimeRangePickerComponent');

@Component({
  selector: 'app-time-range-picker',
  templateUrl: './time-range-picker.component.html',
  styleUrls: ['./time-range-picker.component.scss'],
})
export class TimeRangePickerComponent implements OnInit, OnDestroy {
  @Input()
  fixedMidButtonWidth: number = null;
  private destory$ = new Subject<void>();
  readonly defaultRanges = new BehaviorSubject<TRangePresets>(TIME_RANGE_DEFAULT_RANGES);
  @Output()
  readonly rangeChange = new EventEmitter<TRange>(true);
  private _range = new BehaviorSubject<TRange>(TIME_RANGE_DEFAULT_RANGES.week);
  @Input()
  set range(val: TRange) {
    this._range.next(val);
    this.rangeChange.emit(val);
  }
  @Input()
  showPresets: boolean = true;
  @Input()
  disabled: boolean = false;
  readonly range$ = this._range.asObservable().pipe(distinctUntilChanged());
  readonly range$SameDay = this.range$.pipe(map((x) => isSameDay(x.to, x.from)));
  readonly range$SameYear = this.range$.pipe(
    map((x) => {
      const now = new Date();
      return isSameYear(x.from, now) && isSameYear(x.to, now);
    }),
  );
  ngOnDestroy() {
    this.destory$.next();
    this.destory$.complete();
  }
  constructor(
    private translateService: TranslateService,
    private cldrDialog: CalendarRangePickerService,
  ) {}
  get currentLocale() {
    return this.translateService.currentLang;
  }
  ngOnInit() {}
  private rangeStrat = inject(MAT_DATE_RANGE_SELECTION_STRATEGY) as TimeRangeStrategyService<Date>;
  openRangePicker(ref: ElementRef<any>) {
    const range = this._range.getValue();
    this.cldrDialog
      .open({
        config: {
          elRef: ref,
          presets: TIME_RANGE_DEFAULT_RANGES,
          selected:
            (range && { ...range, rangeType: range.name === TIME_RANGE_CUSTOM_NAME ? null : range.rangeType }) || null,
        },
      })
      .afterClosed()
      .pipe(filter((x: TRange) => !!x))
      .subscribe((x) => (this.range = this.rangeParserFixer({ ...x })));
  }
  selectRange(r: TRange) {
    const isCustom = TIME_RANGE_CUSTOM_NAME === r.name;
    const rangeType = r.rangeType;
    let range = {
      ...r,
      rangeType,
      ...((rangeType && getTimeRangeByType(rangeType)) || {
        name: 'time-range.preset.custom',
        rangeType: null,
      }),
      from: startOfDay(r.from),
      to: endOfDay(r.to),
      custom: isCustom ? (isSameDay(r.from, r.to) ? null : r.custom) : r.custom,
    };
    this.range = this.rangeParserFixer(range);
  }
  private rangeParserFixer(range: TRange) {
    if (this.rangeParser === 'week') {
      range.from = startOfWeek(range.from, { weekStartsOn: 1 });
      range.to = endOfWeek(range.to, { weekStartsOn: 1 });
    }
    return range;
  }
  @Input()
  rangeParser: 'week' | null = null;
  goBack(r: TRange) {
    const subFn = {
      year: subYears,
      quarter: subQuarters,
      month: subMonths,
      week: subWeeks,
      day: subDays,
    };
    let from = new Date(r.from.getTime()),
      to = new Date(r.to.getTime());
    (from = r.custom?.sub ? r.custom.sub(from) : subFn[r.rangeType](from, 1)),
      (to = r.custom?.sub ? r.custom.sub(to) : subFn[r.rangeType](to, 1));
    let range = { ...(r.afterParse?.({ ...r, from, to }) ?? { ...r, from, to }) };
    this.range = this.rangeParserFixer(range);
  }
  goForward(r: TRange) {
    const addFn = {
      year: addYears,
      quarter: addQuarters,
      month: addMonths,
      week: addWeeks,
      day: addDays,
    };
    let from = new Date(r.from.getTime()),
      to = new Date(r.to.getTime());
    from = r.custom?.add ? r.custom.add(from) : addFn[r.rangeType](from, 1);
    to = r.custom?.add ? r.custom.add(to) : addFn[r.rangeType](to, 1);
    let range = { ...(r.afterParse?.({ ...r, from, to }) ?? { ...r, from, to }) };
    this.range = this.rangeParserFixer(range);
  }
}

import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  AfterContentInit,
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import {
  DateRange,
  DefaultMatCalendarRangeStrategy,
  MAT_DATE_RANGE_SELECTION_STRATEGY,
  MatCalendar,
  MatCalendarCellCssClasses,
} from '@angular/material/datepicker';
import { fromRxValue } from '@app/_helpers/utils';
import { TranslateService } from '@ngx-translate/core';
import { differenceInDays, endOfDay, isSameDay, isValid, startOfDay, startOfMonth } from 'date-fns/esm';
import { orderBy } from 'lodash';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith } from 'rxjs/operators';
import { Logger } from 'timeghost-api';
import { CalRangeType, TimeRangeStrategyService } from '../customRangeAdapter';
import { TIME_RANGE_CUSTOM_NAME, TRange, TRangePresets, getTimeRangeByType } from '../time-range-constants';
import { CalendarRangePickerOverlayRef } from './calendar-range-picker-ref';
import { CALENDAR_RANGE_DIALOG_DATA } from './calendar-range-picker-ref-tokens';
import { CalendarRangeConfig } from './calendar-range-picker.service';

const ESCAPE = 27;
const ANIMATION_TIMINGS_IN = '300ms cubic-bezier(0.25, 1.25, 0.25, 1)';
const ANIMATION_TIMINGS_OUT = '300ms cubic-bezier(0.25, 0.85, 0.25, 1)';
const log = new Logger('CalendarRangePickerComponent');

@Component({
  selector: 'app-calendar-range-picker',
  templateUrl: './calendar-range-picker.component.html',
  styleUrls: ['./calendar-range-picker.component.scss'],
  providers: [DefaultMatCalendarRangeStrategy],
  animations: [
    trigger('fade', [
      state('fadeOut', style({ opacity: 0 })),
      state('fadeIn', style({ opacity: 1 })),
      transition('* => fadeIn', animate(ANIMATION_TIMINGS_IN)),
    ]),
    trigger('slideContent', [
      state('void', style({ transform: 'translate3d(0, 0, 0) scale(0.65)', opacity: 0 })),
      state('enter', style({ transform: 'none', opacity: 1 })),
      state('leave', style({ transform: 'translate3d(0, 0, 0) scale(0.65)', opacity: 0 })),
      transition('* => *', animate(ANIMATION_TIMINGS_IN)),
    ]),
  ],
})
export class CalendarRangePickerComponent implements OnInit, OnDestroy, AfterContentInit {
  loading = true;
  animationState: 'void' | 'enter' | 'leave' = 'enter';
  animationStateChanged = new EventEmitter<AnimationEvent>();
  private destroy$ = new Subject<void>();
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
  @ViewChild('startCal', { static: true })
  private startCal: MatCalendar<Date>;
  private ranges = new BehaviorSubject<TRangePresets>(this.config.presets);
  readonly rangeCustomName = TIME_RANGE_CUSTOM_NAME;
  readonly ranges$ = this.ranges.asObservable().pipe(distinctUntilChanged());
  private range = new BehaviorSubject<TRange>(null);
  private get rangeNative() {
    return new DateRange(this.range.value.from, this.range.value.to);
  }
  readonly range$ = this.range.asObservable().pipe(distinctUntilChanged());
  readonly range$SameDay = this.range$.pipe(map((x) => isSameDay(x.from, x.to)));
  readonly range$Date = this.range$.pipe(
    map((x) => {
      if (!x) return new DateRange(null, null);
      return new DateRange(x.from, x.to);
    }),
  );
  readonly calendarValue = fromRxValue(this.range$Date, new DateRange(null, null));
  readonly rangeStart$ = this.range$.pipe(
    filter((x) => !!x?.from && isValid(x.from)),
    map((x) => x.from),
  );
  readonly rangeEnd$ = this.range$.pipe(
    filter((x) => !!x?.to && isValid(x.to)),
    map((x) => x.to),
  );

  private _customRanges = new BehaviorSubject<TRangePresets>(null);
  readonly customRanges$ = this._customRanges.asObservable().pipe(distinctUntilChanged());
  @Input()
  get customRanges() {
    return this._customRanges.getValue();
  }
  set customRanges(val: TRangePresets) {
    this._customRanges.next(val);
  }
  readonly ranges$Custom = combineLatest([this.ranges$.pipe(startWith()), this.customRanges$.pipe(startWith())]).pipe(
    map(([presets, customPresets]) => {
      if (!customPresets) {
        return presets;
      } else if (!presets) {
        return customPresets;
      }
      return { ...presets, ...customPresets };
    }),
    map((x) => {
      return orderBy(x, (y) => y.indexName);
    }),
  );

  @HostListener('document:keydown.escape', ['$event'])
  private handleKeydown(event: KeyboardEvent) {
    this.dialogRef.close();
  }
  constructor(
    private translateService: TranslateService,
    public dialogRef: CalendarRangePickerOverlayRef,
    @Inject(CALENDAR_RANGE_DIALOG_DATA)
    private config: CalendarRangeConfig,
  ) {
    this.ranges.next(this.config.presets);
  }
  onLoad(event: Event) {
    this.loading = false;
  }

  ngOnInit() {
    this.selectRange(this.config.selected, true);
  }
  ngAfterContentInit() {}
  onAnimationStart(event: AnimationEvent) {
    this.animationStateChanged.emit(event);
  }

  onAnimationDone(event: AnimationEvent) {
    this.animationStateChanged.emit(event);
  }
  get currentLang() {
    return this.translateService.currentLang;
  }
  startExitAnimation() {
    this.animationState = 'leave';
  }

  // todo start
  // dragStart(date: Date) {
  //   const value = this.rangeStrategy.createPreview(date, this.calendarValue.value);
  //   this.calendarValue.next(value);
  // }
  // dragEnd(date: Date) {
  //   const value = this.rangeStrategy.createDrag(this.calendarValue.value.start, this.calendarValue.value, date);
  //   this.calendarValue.next(value);
  // }
  // drag(ev: any) {
  //   log.debug('drag', { event: ev });
  // }
  // todo end

  selectRange(ev: TRange, triggerUpdateActiveDate: boolean = true) {
    if (!ev.rangeType) {
      this.setRangeConfig(null);
      ev = { ...ev, rangeType: null, name: TIME_RANGE_CUSTOM_NAME };
    } else if (ev.rangeType === 'day') this.setRangeConfig('day');
    else this.setRangeConfig(ev.rangeType);

    this.range.next(ev);

    if (triggerUpdateActiveDate) {
      try {
        this.updateActiveDate(ev);
      } catch (e) {
        log.error(e);
      }
    }
  }
  updateActiveDate(ev?: TRange) {
    log.debug(ev || this.range.value, this.startCal);
    if (this.startCal) {
      ev = ev ?? this.range.value;
      this.startCal.startAt = this.startCal.activeDate = startOfMonth(ev.from.getTime());
      this.startCal.stateChanges.next();
      // try {
      //   this.startCal.focusActiveCell();
      //   this.endCal.focusActiveCell();
      // } catch {}
    }
  }
  selectedChange(ev: Date) {
    const newRange = this.rangeStrategy.selectionFinished(ev, this.calendarValue.value);
    if (!newRange.end || !this.rangeStrategy.type) {
      this.calendarValue.update(newRange);
      if (newRange.end)
        this.selectRange({
          ...this.range.value,
          ...((this.rangeStrategy.type && getTimeRangeByType(this.rangeStrategy.type)) || {}),
          from: isValid(newRange.start) ? startOfDay(newRange.start) : null,
          to: isValid(newRange.end) ? endOfDay(newRange.end) : null,
          rangeType: this.rangeStrategy.type,
        });
      return;
    }
    if (newRange.end) {
      this.selectRange({
        ...this.range.value,
        ...((this.rangeStrategy.type && getTimeRangeByType(this.rangeStrategy.type)) || {
          name: 'time-range.preset.custom',
        }),
        ...{
          from: isValid(newRange.start) ? startOfDay(newRange.start) : null,
          to: isValid(newRange.end) ? endOfDay(newRange.end) : null,
          rangeType: this.rangeStrategy.type,
        },
      });
    }
    this.startCal.stateChanges.next();
  }
  saveSelection() {
    this.dialogRef.close(this.range.getValue());
  }
  cancel() {
    this.dialogRef.close();
  }

  dtRange() {
    return (date: Date): MatCalendarCellCssClasses => {
      const range = this.range ? this.range.getValue() : null;
      let isRange = false;
      if (range && differenceInDays(range.from, range.to) === 0) {
        return;
      }
      if (range && (isRange = differenceInDays(range.from, range.to) > 0)) {
        log.debug(isRange, date);
        return 'mat-date-range-inBetween';
      }
      if (range && (isRange = isSameDay(range.from, Date.now()))) {
        log.debug(isRange, date);
        return 'mat-date-range-isStart';
      }
      if (range && (isRange = isSameDay(range.to, Date.now()))) {
        log.debug(isRange, date);
        return 'mat-date-range-isEnd';
      }
    };
  }
  private rangeStrategy = inject(MAT_DATE_RANGE_SELECTION_STRATEGY) as TimeRangeStrategyService<Date>;
  setRangeConfig(type: CalRangeType) {
    this.rangeStrategy.setRangeType(type);
    this.startCal.stateChanges.next();
  }
}
